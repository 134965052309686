import { h, Fragment } from "preact";
import { t, translate } from "restaumatic-client";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState, } from "preact/hooks";
import Device from "../../utils/Device";
import PreactModal, { ModalHeader } from "../../components/PreactModal";
import Alert from "../../components/Alert";
import { RadioFieldOption } from "../../components/RadioField";
import { hideModal } from "../../utils/Modals";
import debounce from "../../utils/debounce";
function FulfillmentTimeModal(props) {
    const { id, dataFieldId, modal, onShow, onSubmit, onHidden } = props;
    const hoursListRef = useRef(null);
    const isMobile = Device.screen.isMobileSize();
    const themeName = Skubacz.configuration.theme_name;
    const navigationPageSize = 14;
    const handleSubmit = () => {
        modal.save();
        if (modal.isSaved) {
            void hideModal(id).then(() => onSubmit());
        }
    };
    const handleShow = () => {
        modal.initialize({ navigationPageSize });
        onShow();
    };
    return (h(PreactModal, { id: id, modalClass: themeName !== "active_menu"
            ? "modal-dialog--full-height modal-dialog--scrollable"
            : "", dataFieldId: dataFieldId, onShow: handleShow, onHidden: onHidden },
        h(ModalHeader, { title: modal.title }),
        h("div", { class: "modal-body u-pt0" },
            h("article", { class: "m-fulfillment-time" },
                !modal.isTodayOnly && (h(DaysList, { modal: modal, isMobile: isMobile, hoursListRef: hoursListRef })),
                h(HoursList, { modal: modal, listRef: hoursListRef }))),
        h("div", { class: "modal-footer modal-footer--sticky" },
            h("button", { type: "button", onClick: handleSubmit, class: "btn btn-primary btn-block", "data-field-id": "fulfillmentTime.submit", disabled: !modal.isValid }, translate(t.actions.save)))));
}
const DaysList = observer((props) => {
    const { modal, hoursListRef, isMobile } = props;
    const listRef = useRef(null);
    const [scrollLeftAvailable, setScrollLeftAvailable] = useState(false);
    const [scrollRightAvailable, setScrollRightAvailable] = useState(false);
    const distanceFromEdgeToShowArrow = 30;
    const scrollingDistance = useMemo(() => {
        return listRef.current ? listRef.current.offsetWidth : 500;
    }, [listRef.current]);
    const setArrowsVisibility = useCallback(() => {
        const container = listRef.current;
        if (container === null) {
            return;
        }
        const { scrollLeft, scrollWidth, offsetWidth } = container;
        const distanceFromLeft = scrollLeft;
        const distanceFromRight = scrollWidth - (scrollLeft + offsetWidth);
        setScrollLeftAvailable(distanceFromLeft > distanceFromEdgeToShowArrow);
        setScrollRightAvailable(distanceFromRight > distanceFromEdgeToShowArrow);
    }, [
        listRef,
        distanceFromEdgeToShowArrow,
        setScrollLeftAvailable,
        setScrollRightAvailable,
    ]);
    useEffect(() => {
        if (isMobile)
            return;
        setTimeout(() => {
            setArrowsVisibility();
        }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
    }, [modal.dateItems, setArrowsVisibility]);
    useEffect(() => {
        if (isMobile)
            return;
        const listEl = listRef.current;
        const debouncedScrollHandler = debounce(() => {
            requestAnimationFrame(setArrowsVisibility);
        }, 200);
        listEl === null || listEl === void 0 ? void 0 : listEl.addEventListener("scroll", debouncedScrollHandler);
        return () => {
            listEl === null || listEl === void 0 ? void 0 : listEl.removeEventListener("scroll", debouncedScrollHandler);
        };
    }, [listRef, setArrowsVisibility]);
    const handlePrevDesktop = () => {
        var _a;
        if (listRef.current !== null &&
            listRef.current.scrollLeft <= scrollingDistance &&
            modal.hasPrevItems) {
            modal.getPrev();
            setTimeout(() => { var _a; return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy(-scrollingDistance, 0); });
        }
        else {
            (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy(-scrollingDistance, 0);
        }
    };
    const handleNextDesktop = () => {
        var _a;
        if (listRef.current !== null &&
            listRef.current.scrollWidth - listRef.current.scrollLeft <=
                scrollingDistance &&
            modal.hasNextItems) {
            modal.getNext();
            setTimeout(() => { var _a; return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy(scrollingDistance, 0); });
        }
        else {
            (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy(scrollingDistance, 0);
        }
    };
    const handlePrevMobile = () => {
        modal.hasPrevItems && modal.getPrev();
    };
    const handleNextMobile = () => {
        modal.hasNextItems && modal.getNext();
    };
    const prevArrowVisible = isMobile
        ? modal.hasPrevItems
        : scrollLeftAvailable || (!scrollLeftAvailable && modal.hasPrevItems);
    const nextArrowVisible = isMobile
        ? modal.hasNextItems
        : scrollRightAvailable || (!scrollRightAvailable && modal.hasNextItems);
    return (h("div", { id: "fulfillment-time-days-slider", class: "m-fulfillment-time__slider u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        h("ul", { ref: listRef, class: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" },
            h("li", { class: `m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev ${prevArrowVisible ? "is-visible" : ""}` },
                h("button", { type: "button", class: "m-fulfillment-time__arrow-btn u-btn-unstyled", onClick: isMobile ? handlePrevMobile : handlePrevDesktop, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { class: "icon-btn icon-left", "aria-hidden": "true" }))),
            modal.dateItems.map((dateItem) => (h("li", { class: "m-fulfillment-time__slider-item" },
                h("button", { type: "button", onClick: () => {
                        var _a;
                        modal.setActiveDate(dateItem);
                        (_a = hoursListRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
                    }, class: `m-fulfillment-time__day u-btn-unstyled ${modal.isActiveDate(dateItem) ? "is-active" : ""} ${modal.isDisabledItem(dateItem) ? "is-disabled" : ""}` },
                    h("span", { class: "u-text-bold u-mb1" }, dateItem.title),
                    h("span", { class: "u-text-nowrap" },
                        dateItem.description,
                        modal.isConflictingItem(dateItem) && (h("i", { class: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))))))),
            h("li", { class: `m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next ${nextArrowVisible ? "is-visible" : ""}` },
                h("button", { type: "button", class: "m-fulfillment-time__arrow-btn u-btn-unstyled", onClick: isMobile ? handleNextMobile : handleNextDesktop, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { class: "icon-btn icon-right", "aria-hidden": "true" }))))));
});
const HoursList = observer((props) => {
    const { modal, listRef } = props;
    // Scroll to selected hour item on first render (when modal shown)
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            setTimeout(() => {
                selectedHourItem.scrollIntoView({
                    behavior: "instant",
                    block: "center",
                });
            }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
        }
    }, [listRef]);
    // Scroll to selected hour item when active date change
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            selectedHourItem.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
        }
    }, [listRef, modal.activeDate]);
    return (h("ul", { ref: listRef, class: "m-fulfillment-time__list u-list-unstyled", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_time), tabIndex: -1, "data-field-id": "fulfillmentTime.value", "data-field-type": "radioGroup" },
        modal.dateTimeItems.map((item) => (h("li", { "data-field-id": "fulfillmentTime." + item.stringValue },
            h(RadioFieldOption, { name: "fulfillment-time-hour", value: item.stringValue, label: h(Fragment, null,
                    item.title,
                    modal.isConflictingItem(item) && (h("i", { class: "icon-base icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))), isActive: modal.isActiveValue(item), isDisabled: modal.isDisabledItem(item), childrenCssClass: "m-radio__children--align-to-label", onChange: () => modal.setValue(item.value), onBlur: () => null },
                h(Fragment, null,
                    item.description !== undefined && (h("span", null, item.description)),
                    modal.isConflictingItem(item) && (h(Alert, { type: "warning", extraClasses: "u-my0", message: h(Fragment, null,
                            h("p", null, translate(t.orders.form.conflicting_products_warning)),
                            h("ul", null, item.conflictingProducts.map((productName) => (h("li", null, productName))))) }))))))),
        modal.dateTimeItems.length === 0 && (h(Alert, { type: "danger", message: translate(t.orders.form.unavailable_date) }))));
});
export default observer(FulfillmentTimeModal);
